<template>
  <div>
    <div class="img_title">
      <div style="width: 1200px; margin: 0 auto; position: relative">
        <span class="box_title">
          {{ type == "0" ? "用户注册" : "重置密码" }}
        </span>
      </div>
      <img style="width: 100%; height: 100%"
           src="../../assets/img/title_1.jpg"
           alt="" />
    </div>
    <div class="login_box_right">
      <el-form :model="ruleForm"
               status-icon
               :rules="rules"
               ref="ruleForm"
               :label-position="labelPosition"
               label-width="110px"
               class="demo-ruleForm">
        <el-form-item prop="mobile"
                      label="手机号码"
                      class="login_form_item">
          <el-input v-model.number="ruleForm.mobile"
                    style="width:340px"></el-input>
        </el-form-item>
        <el-form-item prop="code"
                      label="短信验证码"
                      class="login_form_item my_code">
          <el-input v-model="ruleForm.code"
                    style="width:340px"></el-input>
          <el-button class="get_code"
                     :disabled="disabled"
                     @click="handle_graph_code">{{ valiBtn }}</el-button>
        </el-form-item>
        <el-form-item prop="password1"
                      label="密码"
                      class="login_form_item">
          <el-input type="password"
                    v-model="ruleForm.password1"
                    autocomplete="off"
                    style="width:340px"></el-input>
        </el-form-item>
        <el-form-item label="确认密码"
                      prop="password2"
                      class="login_form_item">
          <el-input type="password"
                    v-model="ruleForm.password2"
                    autocomplete="off"
                    style="width:340px"></el-input>
        </el-form-item>
      </el-form>
      <div class="sub_box">
        <button class="sub"
                @click="handle_sub">
          {{ type == "0" ? "注册" : "确定" }}
        </button>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible"
               class="my_dialog"
               width="529px"
               :show-close="false">
      <p class="my_dialog_title"><img style="width:65px;height:65px;position: relative;top: 18px;margin-right:10px;"
             src="@/assets/img/chenggong.png"
             alt="">账号注册成功！</p>
      <p class="my_dialog_text">恭喜您已经成功注册恒银期货账号！3S后回首页，或直接点击<span style="color:#C6271E;"
              @click="handle_return">回首页</span>！</p>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible1"
               class="my_dialog"
               width="529px"
               :show-close="false">
      <p class="my_dialog_title"><img style="width:65px;height:65px;position: relative;top: 18px;margin-right:10px;"
             src="@/assets/img/chenggong.png"
             alt="">密码重置成功!</p>
      <p class="my_dialog_text">恭喜您已经成功重置密码！3S后回首页，或直接<span style="color:#C6271E;"
              @click="handle_return">回首页</span>！</p>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible2"
               class="my_dialog graph_code_my_dialog"
               width="529px"
               :show-close="false">
      <div class="graph_code_box">
        <img :src="graph_code"
             class="graph_code_img"
             @click="handle_refresh" />
        <el-input class="graph_code"
                  v-model="captcha">
          <el-button slot="append"
                     @click="getCode">提交</el-button>
        </el-input>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,30}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!reg.test(value)) {
        // 密码长度8-30位，只能为字母，数字，下划线''
        callback(new Error('密码必须是由8-30位字母+数字组合'))
      } else {
        if (this.ruleForm.password2 !== "") {
          this.$refs.ruleForm.validateField("password2");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (this.ruleForm.password1) {
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== this.ruleForm.password1) {
          callback(new Error("两次输入密码不一致!"));
        } else {
          callback();
        }
      }
    };
    var checkPhone = (rule, value, callback) => {
      const reg =
        /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
      if (!value) {
        return callback("请输入手机号");
      } else if (!reg.test(value)) {
        return callback("手机号格式不正确");
      }
      callback();
    };
    return {
      captcha: '',
      graph_code: '',
      dialogVisible2: false,
      labelPosition: 'right',
      clearSetTimeout: null,
      dialogVisible: false,
      dialogVisible1: false,
      type: this.$route.query.type,
      valiBtn: "获取验证码",
      disabled: false,
      verify_account: false,
      timer: null,
      ruleForm: {
        password1: "",
        password2: "",
        mobile: "",
        code: "",
      },
      rules: {
        password1: [{ required: true, validator: validatePass, trigger: "blur" }],
        password2: [{ required: true, validator: validatePass2, trigger: "blur" }],
        mobile: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  mounted () {
    this.labelPosition = window.screen.width < 1200 ? 'top' : 'right'
  },
  watch: {
    $route: "getType",
  },
  methods: {
    handle_refresh () {
      this.graph_code = `/api/web/member/captcha?mobile=${this.ruleForm.mobile}&t=${Math.random()}`
    },
    handle_graph_code () {
      this.$refs["ruleForm"].validateField("mobile", (err) => {
        if (err) {
          return;
        } else {
          this.graph_code = `/api/web/member/captcha?mobile=${this.ruleForm.mobile}&t=${Math.random()}`
          this.dialogVisible2 = true
        }
      });
    },
    handle_return () {
      clearTimeout(this.clearSetTimeout);
      this.$router.push('/')
    },
    getType () {
      this.type = this.$route.query.type;
      this.ruleForm = {
        password1: "",
        password2: "",
        mobile: "",
        code: "",
      }
      this.$refs["ruleForm"].resetFields();
    },
    handle_sub () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.type == "0") {
            this.$axios({
              method: "POST",
              headers: {
                'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
              },
              data: this.qs.stringify(this.ruleForm),
              url: "/api/web/member/register",
            }).then((res) => {
              if (res.data.status === 200) {
                this.dialogVisible = true;
                this.clearSetTimeout = setTimeout(() => {
                  this.handle_return()
                }, 3000);
                clearInterval(this.timer);
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.errorMessage,
                  type: "error",
                });
              }
            });
          } else if (this.type == "1") {
            this.$axios({
              method: "POST",
              headers: {
                'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
              },
              data: this.qs.stringify(this.ruleForm),
              url: "/api/web/member/set_password",
            }).then((res) => {
              if (res.data.status === 200) {
                this.dialogVisible1 = true;
                this.clearSetTimeout = setTimeout(() => {
                  this.handle_return()
                }, 3000);
                clearInterval(this.timer);
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.errorMessage,
                  type: "error",
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    getCode () {
      this.$axios({
        method: "POST",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        data: this.qs.stringify({
          mobile: this.ruleForm.mobile,
          captcha: this.captcha,
          isLogin: false
        }),
        url: "/api/web/member/send_verify_code",
      }).then((res) => {
        if (res.data.status == 200) {
          this.$message({
            showClose: true,
            message: '验证码已发送，请注意查看',
            type: "success",
          });
          this.dialogVisible2 = false
          this.tackBtn(); //验证码倒数60秒
        } else {
          this.$message({
            showClose: true,
            message: res.data.errorMessage,
            type: "error",
          });
          this.handle_refresh();
        }
      });
    },
    tackBtn () {
      //验证码倒数60秒
      clearInterval(this.timer);
      let time = 60;
      this.timer = setInterval(() => {
        if (time == 0) {
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
  },
};
</script>
<style scoped>
.graph_code {
  width: 45%;
}
.graph_code_img {
  height: 50px;
}
.graph_code_box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.my_dialog_text {
  width: 325px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #666666;
  line-height: 30px;
  margin: 0 auto;
  margin-top: 34px;
  text-align: center;
  margin-bottom: 43px;
  cursor: pointer;
}
.my_dialog_title {
  margin: 0 auto;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  text-align: center;
  margin-top: 40px;
}
.get_code {
  position: absolute;
  top: 6px;
  right: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c11d14 !important;
  cursor: pointer;
  border: none;
  padding: 12px 0 !important;
  background: rgba(0, 0, 0, 0) !important;
}
.get_code:focus,
.get_code:hover {
  background: rgba(0, 0, 0, 0) !important;
}
.login_tab {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sub_box {
  width: 450px;
  margin: 44px auto 20px auto;
  text-align: right;
}
.sub {
  width: 340px;
  height: 50px;
  background: #c11d14;
  border: 1px solid #c11d14;
  border-radius: 3px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  cursor: pointer;
}
.login_form_item {
  width: 450px;
  margin: 25px auto;
  position: relative;
}
.login_title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  text-align: center;
  margin: 68px 0 61px 0;
}
.login_box {
  width: 780px;
  height: 500px;
  display: flex;
}
.login_img {
  width: 340px;
  height: 500px;
}
.login_box_right {
  width: 1220px;
  height: 450px;
  margin: 73px auto 0 auto;
}
.img_title {
  width: 100%;
  background: #ccc;
}
.box_title {
  position: absolute;
  top: 84px;
  left: 23px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
</style>
